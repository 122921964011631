import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = props => (
  <Layout>
    <div className="container mt-7">
      <div className="row justify-content-start text-center">
        <div className="col-12">
          <h1>Not Found</h1>
          <p>Ooopsie. The page you tried to access does not exist.</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
